.post-content {
  table {
    border-collapse: collapse;
    margin: 25px 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  table thead tr {
    background-color: $light-border-color;
    color: $light-color;
    text-align: left;
    .dark-theme & {
      background-color: $dark-border-color;
      color: $dark-color;
    }
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody tr {
    border-bottom: 1px solid $light-border-color;
    .dark-theme & {
      border-bottom: 1px solid $dark-border-color;
    }
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid $light-border-color;
    .dark-theme & {
      border-bottom: 2px solid $dark-border-color;
    }
  }
}
